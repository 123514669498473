import React, { useContext, useEffect, useState, useReducer } from "react";
import {
  View,
  StyleSheet,
  TouchableOpacity,
  Image,
  ScrollView,
  Dimensions,
  ImageBackground,
} from "react-native";
import { SafeAreaView } from "react-navigation";
import { Text, Button } from "react-native-elements";
import BackIcon from "../../images/back_arrow.png";
import GustosListView from "../../components/Listview/GustosListView";
import { urlImage } from "../../utils/AppConst";
import ProductListView from "../../components/Listview/ProductListView";
import { navigate } from "../../utils/NavigationRef";
import FoodCard from "../../components/Cards/FoodCard";
import { Context as UserContext } from "../../dataStore/userAccessContext";

const screenWidth = Dimensions.get("window").width;
var combosLocales = [];
let qtyProducto = 1;

const ProductDetailScreen = ({ navigation }) => {
  const { state, onAddToCart, onAddGusto, onViewCart } = useContext(UserContext);
  const [gustosLocales, setGustoLocal] = useState([]);
  const [valoresAdicionales, setValoresAdicionales] = useState(0);
  const [localQty, setLocalQty] = useState(1);
  //const [combosLocales, setCombosLocales] = useState([]);



  const { cartItems } = state;
  const { params } = navigation.state;

  useEffect(() => {
    onViewCart();
  }, []);

  const { _id, descripcion, foto, description, precioventaconiva, gustos, presentacion, combos } = params;

  let image = foto;

  const item = { item: params };

  const didTapBack = () => {
    navigation.goBack();
  };

  const changeQtyLocal = (qty) => {
    var cantidadMaxima = 0;
    let cantidadActual = 0;
    var tipocomboid = 0;
    var puedeCambiarCantidad = true;
    combosLocales.map((combo,key) => {
      if(combo.tipocomboid != tipocomboid){
        tipocomboid = combo.tipocomboid;
        cantidadMaxima = combo.cantidad;
        if (combo.preciocombo < 100) {
          cantidadActual = parseInt(combo.cantidadcombo);
        }
        if (cantidadActual > cantidadMaxima) {
          puedeCambiarCantidad = false;
        }
      }
      else {
        if (combo.preciocombo < 100) {
          cantidadActual = cantidadActual + parseInt(combo.cantidadcombo);
        }
        if (cantidadActual > cantidadMaxima) {
          puedeCambiarCantidad = false;
        }
      }
    });
    if (puedeCambiarCantidad) {
      qtyProducto = qty;
      setLocalQty(qty);
      return true;
    }
    alert('Actualmente dispone de productos en combo que superan la cantidad que desea disminuir, para proceder elimine productos del combo hasta la cantidad permitida');
    return false;
  }

  const didAddToCard = (item, qty) => {
    if(qty > 0 || qtyProducto > 0){
      var cantidadMaxima = 0;
      let cantidadActual = 0;
      var tipocomboid = 0;
      var combosSeleccionados = true;
      /*if (item.combos.length > 0 && combosLocales.length == 0) {
        combosSeleccionados = false;
      }*/
      combosLocales.map((combo,key) => {
        if(combo.tipocomboid != tipocomboid){
          tipocomboid = combo.tipocomboid;
          cantidadMaxima = combo.cantidad;
          if (combo.preciocombo < 100) {
            cantidadActual = parseInt(combo.cantidadcombo);
          }
          if (cantidadActual != cantidadMaxima && key == (combo.length - 1)) {
            combosSeleccionados = false;
          }
        }
        else {
          if (combo.preciocombo < 100) {
            cantidadActual = cantidadActual + parseInt(combo.cantidadcombo);
          }
          if (cantidadActual != cantidadMaxima && key == (combo.length - 1)) {
            combosSeleccionados = false;
          }
        }
      });
      var tipoComboId = 0;
      var esAdicion = true;
      combos.map((combo)=>{
        if (combo.preciocombo < 100) {
          esAdicion = false;
        }
        if (tipoComboId != combo.tipocomboid) {
          tipoComboId = combo.tipocomboid;
          var cantidadMax = combo.cantidad;
          if(cantidadMax > 0){
            var cantidadSel = 0;
            combosLocales.filter((e) => e.tipocomboid == combo.tipocomboid).map((comboSel) => {
              cantidadSel += comboSel.cantidadcombo;
            });
            if (cantidadSel < cantidadMax && !esAdicion) {
              combosSeleccionados = false;
            }
          }
        }
      })
      if (!combosSeleccionados) {
        alert("¡Hay productos pendientes de seleccionar!, Asegurate por favor de elegir la cantidad de productos incluidos del combo");
        return;
      }
      onAddToCart({...item,['gustos_selected']:gustosLocales,['combos_selected']:combosLocales,['precioventaconiva']:parseInt(item.precioventaconiva)+valoresAdicionales},(qty ?? qtyProducto), true);
      //setGustoLocal([]);
      //setCombosLocales([]);
      combosLocales = [];
      navigation.goBack();
      navigate("Shoping");
    }
    onAddToCart(item, (qty ?? qtyProducto));
    //navigation.goBack();
  };

  const didAddRemove = (item, qty) => {
    onAddToCart(item, qty);
    /*if(!qty){
      navigation.goBack();
    }*/
  };

  const addComboLocal = (item, qty) => {
    var cantidadMaxima = item.cantidad;
    let cantidadActual = 0;
    var indexCombo = -1;
    var newValorAdicional = 0;
    combosLocales.map((combo, key) => {
      if(combo.tipocomboid == item.tipocomboid && combo.comboid != item.comboid){
        if (combo.preciocombo < 100) {
          cantidadActual = cantidadActual+parseInt(combo.cantidadcombo);
        }
        else {
          newValorAdicional = newValorAdicional + (parseInt(combo.preciocombo)*parseInt(combo.cantidadcombo));
        }
      }
      else {
        if (combo.preciocombo > 100 && combo.comboid != item.comboid) {
          newValorAdicional = newValorAdicional + (parseInt(combo.preciocombo)*parseInt(combo.cantidadcombo));
        }
      }
      if (combo.comboid == item.comboid) {
        indexCombo = key;
      }
    });
    var qtyCombo = qty;
    if (item.preciocombo > 100) {
      qtyCombo = 0;
      setValoresAdicionales((qty*item.preciocombo)+newValorAdicional);
    }
    if((cantidadActual+qtyCombo) <= (cantidadMaxima*qtyProducto)){
      //var newCombos = combosLocales;
      if(indexCombo != -1){
        if (qty == 0) {
          combosLocales.splice(indexCombo,1);
        }
        else {
          combosLocales.splice(indexCombo,1,{...item,["cantidadcombo"]:qty});
        }
      }
      else {
        combosLocales.push({...item,["cantidadcombo"]:qty});
      }
      //setCombosLocales(newCombos);
      //combosLocales = newCombos;
      return true;
    }
    return false;
  }

  const onGustoSelect = (codigo,value, added = false) => {
    if(added){
      onAddGusto(params, codigo, value);
      return;
    }
    var gustos = params.gustos ?? [];
    //var prodIndex = 0;//gustosLocales.findIndex((e) => e.idproducto == params.idproducto);
    //if (prodIndex != -1) {
      gustosTemp = gustosLocales ?? [];
    //}
    if (!value) {
      indexRemove = gustosTemp.findIndex((e) => e.codigo == codigo);
      gustosTemp.splice(indexRemove,1);
    }
    else {
      //gustos = prodIndex !== -1 ? (carrito[prodIndex].gustos ?? []) : gustos;
      var gustoIndex = gustos.findIndex((e) => e.codigo == codigo);
      gustosTemp.push(gustos[gustoIndex]);
    }
    setGustoLocal(gustosTemp);
  }

  function mostrarFoto() {
    //let input = document.frmConvert.hex.value.replace(/[^A-Fa-f0-9]/g, "");
    if (foto % 2) {
        console.log("cleaned hex string length is odd.");
        return;
    }

    let binary = new Array();
    for (let i = 0; i < foto.length / 2; i++) {
        let h = foto.substr(i * 2, 2);
        binary[i] = parseInt(h, 16);
    }

    let byteArray = new Uint8Array(binary);
    let img = document.querySelector('.heximage');

    return window.URL.createObjectURL(new Blob([byteArray], { type: 'application/octet-stream' }));
    //document.body.appendChild(img)
}

  return (
    <SafeAreaView style={styles.contentView} forceInset={{ top: "always" }}>
      <View style={styles.titleView}>
        <TouchableOpacity onPress={() => didTapBack()}>
          <Image style={styles.imgIcon} source={BackIcon} />
        </TouchableOpacity>
        <Text h4 style={{ flex: 1, textAlign: "center", marginRight: 40 }}>
          {descripcion}
        </Text>
      </View>
      <View style={styles.listView}>
      <ScrollView>
        <ImageBackground
          source={{
            uri: foto//mostrarFoto(),
          }}
          style={styles.coverImage}
        >

        </ImageBackground>
        <View style={styles.foodInfo}>
          <Text style={styles.foodDetails}>{ presentacion && presentacion != "" ? presentacion : "DALE TU TOQUE PERSONAL " }</Text>
        </View>
        {gustos && gustos.length > 0 &&
          <View style={styles.foodInfo1}>
            <Text style={styles.foodDetails1}>{"GUSTOS"}</Text>
          </View>
        }
        {gustos && gustos.length > 0 ?
          <GustosListView size="medium" gustos={gustos} gustosLocales={gustosLocales} didSelectItem={onGustoSelect} producto={params} />
        :
        <View style={styles.listView}></View>
        }
        {
          combos && combos.length > 0 ?
          <ProductListView
            size="combos"
            disable={false}
            foods={combos}
            didSelectItem={() => {}}
            didAddToCart={() => {}}
            qtyProducto={localQty}
            didAddRemove={addComboLocal}
          />
          :
          <View style={styles.listView}></View>
        }
        </ScrollView>
        <View style={{ height: 80 }}>
          <FoodCard
            key={`${_id}`}
            size={"withoutpic"}
            data={item}
            cartItems={cartItems}
            valoresAdicionales={valoresAdicionales}
            disable={true}
            onSelect={changeQtyLocal}
            onAddToCart={didAddToCard}
            didAddRemove={didAddRemove}
          />
        </View>
      </View>
    </SafeAreaView>
  );
};

const styles = StyleSheet.create({
  contentView: {
    backgroundColor: "#F2F2F2",
    flex: 1,
    justifyContent: "space-between",
  },
  titleView: {
    flex: 1,
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  listView: {
    flex: 11,
  },
  coverImage: {
    width: screenWidth,
    height: 230,
    justifyContent: "flex-end",
  },

  imgIcon: {
    width: 40,
    height: 50,
  },
  searchOptions: {
    display: "flex",
    height: 60,
    justifyContent: "space-around",
    flexDirection: "row",
    alignItems: "center",
    marginLeft: 10,
  },
  topCategory: {
    height: 100,
    backgroundColor: "green",
  },

  amountDetails: {
    flexDirection: "row",
    justifyContent: "space-between",
    padding: 10,
    margin: 5,
  },
  foodTitle: {
    color: "white",
    fontWeight: "700",
  },
  foodInfo: {
    height: 90,
    backgroundColor: "rgba(0,0,0,0.5)",
    fontSize: 12,
  },
  foodDetails: {
    fontSize: 15,
    color: "white",
    fontWeight: "400",
    textAlign:"center"
  },
  foodInfo1: {
    height: 35,
    backgroundColor: "rgba(0,0,0,0.5)",
    padding: 5,
    marginTop: 5,
  },
  foodDetails1: {
    fontSize: 17,
    color: "white",
    fontWeight: "400",
    textAlign:"center"
  },
});

ProductDetailScreen.navigationOptions = () => {
  return {
    header: null,
  };
};

export default ProductDetailScreen;
