import React, { useContext, useEffect, useState } from "react";
import { View, StyleSheet, Text, Image } from "react-native";
import { SafeAreaView } from "react-navigation";
import SearchBar from "../../components/InputFields/SearchBar";
import BackIcon from "../../images/back_arrow.png";
import { TouchableOpacity } from "react-native-gesture-handler";
import { navigate } from "../../utils/NavigationRef";
import FoodListView from "../../components/Listview/ProductListView";

import { Context as UserContext } from "../../dataStore/userAccessContext";

const SearchScreen = ({ navigation }) => {
  const { state, onAddToCart, onViewCart } = useContext(UserContext);

  const { foods, restaurants } = state;

  const [isEditing, setIsEditing] = useState(false);
  const [keyword, setKeyword] = useState("");

  const didTapBack = () => {
    navigation.goBack();
  };

  const didSelectItem = (item) => {
    if (item.gustos && item.gustos.length > 0) {
      navigate("FoodDetails", item);
    }
  };

  const didAddToCard = (item) => {
    if (item.gustos && item.gustos.length > 0) {
      navigate("FoodDetails", item);
    }
    else {
      onAddToCart(item);
    }
  };

  const didAddRemove = (item, qty) => {
    onAddToCart(item, qty);
  };

  function mostrarFoto() {
    //let input = document.frmConvert.hex.value.replace(/[^A-Fa-f0-9]/g, "");
    if (foto % 2) {
        console.log("cleaned hex string length is odd.");
        return;
    }

    let binary = new Array();
    for (let i = 0; i < foto.length / 2; i++) {
        let h = foto.substr(i * 2, 2);
        binary[i] = parseInt(h, 16);
    }

    let byteArray = new Uint8Array(binary);
    let img = document.querySelector('.heximage');

    return window.URL.createObjectURL(new Blob([byteArray], { type: 'application/octet-stream' }));
    //document.body.appendChild(img)
}

  const onTextChange = (text) => {
    setIsEditing(true);
    setKeyword(text);
  };

  const onEndEditing = () => {
    setIsEditing(false);
  };

  var productos = [];
  restaurants[0].grupos.map((el)=>{
     productos = productos.concat(el.productos)
   });

  return (
    <SafeAreaView style={styles.contentView} forceInset={{ top: "always" }}>
      <View style={styles.titleView}>
        <View style={styles.searchOptions}>
          <TouchableOpacity onPress={() => didTapBack()}>
            <Image style={styles.imgIcon} source={BackIcon} />
          </TouchableOpacity>
          <SearchBar onTextChange={onTextChange} />
        </View>
      </View>
      <View style={styles.listView}>
        <FoodListView
          foods={
            isEditing
              ? productos.filter((item) => {
                  return item.descripcion.toUpperCase().includes(keyword.toUpperCase());
                })
              : productos
          }
          style={{ flex: 1 }}
          size={"small"}
          didSelectItem={didSelectItem}
          didAddToCart={didAddToCard}
          didAddRemove={didAddRemove}
        />
      </View>
    </SafeAreaView>
  );
};

const styles = StyleSheet.create({
  contentView: {
    backgroundColor: "#F2F2F2",
    flex: 1,
    justifyContent: "space-between",
  },
  titleView: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
  },
  listView: {
    flex: 9,
  },
  imgIcon: {
    width: 40,
    height: 50,
  },
  searchOptions: {
    display: "flex",
    height: 60,
    width: "100%",
    justifyContent: "space-around",
    flexDirection: "row",
    alignItems: "center",
    marginLeft: 10,
  },
  topCategory: {
    height: 100,
    backgroundColor: "green",
  },
});

SearchScreen.navigationOptions = () => {
  return {
    header: null,
  };
};

export default SearchScreen;
