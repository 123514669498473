import React from "react";
import { View, Text, StyleSheet, FlatList, ActivityIndicator, Colors } from "react-native";

import Restaurant from "../../components/Cards/Restaurant";

const TopRestaurants = ({
  restaurants,
  size,
  horizontal,
  didSelectItem,
  disable,
}) => {
  const onSelectItem = ({ item }) => {
    didSelectItem(item);
  };

  if (restaurants && restaurants.length == 1) {
    //didSelectItem(restaurants[0]);
  }

  return (
    restaurants && restaurants.length > 0
    ?
    <FlatList
      horizontal={horizontal ? true : false}
      showsHorizontalScrollIndicator={false}
      showsVerticalScrollIndicator={false}
      data={restaurants}
      renderItem={(item) => (
        <Restaurant
          size={size}
          data={item}
          disable={disable}
          onSelect={onSelectItem}
        />
      )}
      keyExtractor={(item) => item.idsucursal.toString()}
    />
    :
    <View style={styles.container}>
        <ActivityIndicator animating={true} size="large" />
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  text: {
    fontSize: 24,
    fontWeight: 'bold',
  },
});

export default TopRestaurants;
