import React, { useContext, useEffect, useState } from "react";
import { View, Text, StyleSheet, FlatList } from "react-native";
import GustosCard from "../Cards/GustosCard";
import { Context as UserContext } from "../../dataStore/userAccessContext";

const GustosListView = ({
  gustos,
  size,
  horizontal,
  didSelectItem,
  didAddToCart,
  didAddRemove,
  producto,
  gustosLocales,
  disable
}) => {
  const { state, onViewCart } = useContext(UserContext);
  const [ selectGusto, setSelectGusto ] = useState(0);
  const { cartItems  } = state;
  useEffect(() => {
    onViewCart();
  }, []);

  const onSelect = (codigo, value, added = false) => {
    didSelectItem(codigo, value, added);
    if(value){
      setSelectGusto(codigo)
    }
    else {
      setSelectGusto("100"+codigo)
    }
  }

  return (
    <FlatList
      horizontal={horizontal ? true : false}
      showsHorizontalScrollIndicator={false}
      showsVerticalScrollIndicator={false}
      extraData={selectGusto}
      data={gustos}
      renderItem={(item, i) => (
        <GustosCard
          key={`gusto_${item.item.codigo}`}
          size={size}
          data={item}
          disable={disable}
          producto={producto}
          isAdded={false}
          cartItems={cartItems}
          gustosLocales={gustosLocales}
          onSelect={onSelect}
          onAddToCart={didAddToCart}
          didAddRemove={didAddRemove}
        />
      )}
      keyExtractor={(item, index) => item.toString()}
    />
  );
};

export default GustosListView;
