import React, { useEffect, useContext } from "react";
import { Context as UserAccessContext } from "../dataStore/userAccessContext";

const AuthCheckScreen = () => {
  const { onCheckLogin } = useContext(UserAccessContext);

    const createWompiScript = () => {
      var wompi_script = document.createElement('script');

      wompi_script.setAttribute('src','https://checkout.wompi.co/widget.js');

      document.head.appendChild(wompi_script);
    }


  useEffect(() => {
    createWompiScript();
    onCheckLogin();
  }, []);
  return null;
};

export default AuthCheckScreen;
