import React, { useContext, useEffect, useState } from "react";
import { View, StyleSheet, Image } from "react-native";
import { SafeAreaView } from "react-navigation";
import SearchBar from "../../components/InputFields/SearchBar";
import TopCategory from "../../components/TopCategoryList";
import TopFoodList from "../../components/Listview/ProductListView";
import { navigate } from "../../utils/NavigationRef";
import { TouchableOpacity, ScrollView } from "react-native-gesture-handler";
import HambarIcon from "../../images/hambar.png";
import { Ionicons } from "@expo/vector-icons";
import { Text } from "react-native-elements";
import ClienteModal from "../shoping/clienteModal";

//Context
import { Context as UserContext } from "../../dataStore/userAccessContext";
import TopRestaurants from "../foods/TopRestaurants";

var firstRefresh = true;

const HomeScreen = ({ navigation }) => {
  const { state, onCheckAvailability, fetchTopRestaurants } = useContext(
    UserContext
  );
  const [requestCliente, setRequestCliente] = useState(false);
  const [location, setLocation] = useState(null);
  const [address, setAddress] = useState(null);
  const [error, setError] = useState(null);

  const { foods, restaurants } = state;

  /**
   * LifeCycle Methoda
   */
  useEffect(() => {
    fetchTopRestaurants();
    //onCheckAvailability();
  }, []);


  /**
   * USER Actions
   */
  const didTapOptions = () => {
    console.log("Show Options");
  };

  const goToSearchPage = () => {
    navigate("Search");
  };

  const didSelectItem = (item) => {
    navigate("ProductDetail", item);
  };

  const didSelectRestaurant = (item) => {
    document.title= item.nombre
    navigate("RestaurantDetail", item);
  };

  const getCliente = async (cliente) => {
    setRequestCliente(false);
    navigate("RestaurantDetail", restaurants[0]);
  }

  const handleGetLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          setLocation({ latitude, longitude });

          fetch(
            `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=AIzaSyAN8wBu6FBjhyWdJ8_uUttR_9mSttsZa7c`
          )
            .then((response) => response.json())
            .then((data) => {
              if (data.results && data.results.length > 0) {
                setAddress(data.results[0].formatted_address);
                localStorage.setItem('direccion_gps',data.results[0].formatted_address);
                //setRequestCliente(true);
              } else {
                setError("No address found");
                //window.alert('Error: No se econtró la dirección');
                //setRequestCliente(true);
              }
            })
            .catch((error) => {
              setError("Error fetching address");
              //window.alert('Error: No se pudo conectar con el servidor');
              //setRequestCliente(true);
            });
        },
        (error) => {
          setError(error.message);
          setLocation(null);
          //window.alert('Error: '+ error.message);
          //setRequestCliente(true);
        }
      );
    } else {
      setError("Geolocation is not supported by this browser.");
      //setRequestCliente(true);
      //window.alert('Error: No se puede obtener la ubicación');
    }
  };

  if(restaurants && restaurants.length == 1 && firstRefresh){
    firstRefresh = false;
    document.title= restaurants[0].nombre
    const savedData = JSON.parse(localStorage.getItem('userData'));
    if(!savedData || !savedData.address || savedData.address == ''){
      handleGetLocation();
      navigate("RestaurantDetail", restaurants[0]);
    }
    else{
      navigate("RestaurantDetail", restaurants[0]);
    }
  }

  if(requestCliente){
    return(
      <SafeAreaView style={styles.contentView} forceInset={{ top: "always" }}>
     <ClienteModal
          visible={requestCliente}
          onClose={getCliente}
        />
        </SafeAreaView>
      );
  }

  return (
    <SafeAreaView forceInset={{ top: "always" }}>
      <View>
        {/*<View style={styles.searchOptions}>
          <SearchBar didTouch={() => goToSearchPage()} isHome={true} />
          <TouchableOpacity onPress={() => didTapOptions()}>
            <Image style={styles.imgIcon} source={HambarIcon} />
          </TouchableOpacity>
        </View>*/}
        <ScrollView showsVerticalScrollIndicator={false}>
          {/*<TopCategory style={styles.topCategory} />*/}
          <View style={styles.choiceView}>
            <Text h4 style={styles.choiceText}>
              {restaurants && restaurants.length > 0 ? "Sucursales" : ""}
            </Text>
          </View>
          {
            <TopRestaurants
              restaurants={restaurants}
              size={"medium"}
              didSelectItem={didSelectRestaurant}
            />
          }
        </ScrollView>
      </View>
    </SafeAreaView>
  );
};

const styles = StyleSheet.create({
  contentView: {
    backgroundColor: "#F2F2F2",
    flex: 1,
    justifyContent: "space-between",
  },
  imgIcon: {
    width: 50,
    height: 30,
  },
  searchOptions: {
    display: "flex",
    height: 60,
    justifyContent: "space-around",
    flexDirection: "row",
    alignItems: "center",
    marginRight: 10,
  },
  topCategory: {
    height: 100,
    backgroundColor: "#CACACA",
  },
  choiceView: {
    height: 40,
    marginLeft: 10,
    marginRight: 30,
    borderBottomColor: "rgba(0,0,0,0.2)",
    borderBottomWidth: 0.2,
    justifyContent: "flex-start",
  },
  choiceText: {
    fontWeight: "700",
    color: "#f15b5d",
    textAlign:"center"
  },
});

HomeScreen.navigationOptions = () => {
  return {
    header: null,
    tabBarLabel: "Inicio",
    tabBarIcon: ({ tintColor }) => (
      <Ionicons name="md-checkmark-circle" size={32} color="green" />
    ),
  };
};

export default HomeScreen;
