import React, { useContext, useEffect } from "react";
import { View, Text, StyleSheet, FlatList } from "react-native";
import FoodCard from "../Cards/FoodCard";
import { Context as UserContext } from "../../dataStore/userAccessContext";

const ProductListView = ({
  foods,
  size,
  horizontal,
  didSelectItem,
  didAddToCart,
  didAddRemove,
  qtyProducto,
  disable
}) => {
  const { state, onViewCart} = useContext(UserContext);
  const { cartItems } = state;
  useEffect(() => {
    onViewCart();
  }, []);

  if(size == 'combos'){
    let comboid = 0;
    return(
      <FlatList
        horizontal={horizontal ? true : false}
        showsHorizontalScrollIndicator={false}
        showsVerticalScrollIndicator={false}
        data={foods}
        extraData={{ carrito: cartItems, qtyLocal:qtyProducto }}
        renderItem={(item, i) => {
          if (item.item.tipocomboid != comboid) {
            comboid = item.item.tipocomboid;
            /*let cantidadCarrito = 1;
            cartItems.map((cart) => {
              if (cart.idproducto.toString() === item.item.productoidhijo.toString() && cart.cantidad > 0) {
                cantidadCarrito = cart.cantidad;
              }
            });*/
            return (
              <View>
                <View style={styles.foodInfo}>
                  <Text style={styles.foodDetails}>{item.item.descripcion + (item.item.cantidad > 0 ? (", Max: " + item.item.cantidad*qtyProducto) : "") }</Text>
                </View>
                <FoodCard
                  key={`prodList_${i}`}
                  size={size}
                  data={item}
                  disable={disable}
                  cartItems={cartItems}
                  onSelect={didSelectItem}
                  onAddToCart={didAddToCart}
                  didAddRemove={didAddRemove}
                />
              </View>
            )
          }
          else {
            return (
              <FoodCard
                key={`prodList_${i}`}
                size={size}
                data={item}
                disable={disable}
                cartItems={cartItems}
                onSelect={didSelectItem}
                onAddToCart={didAddToCart}
                didAddRemove={didAddRemove}
              />
            );
          }
        }}
        keyExtractor={(item, key) => item.productoid.toString()}
      />
    );
  }

  return (
    <FlatList
      horizontal={horizontal ? true : false}
      showsHorizontalScrollIndicator={false}
      showsVerticalScrollIndicator={false}
      data={foods}
      extraData={cartItems}
      renderItem={(item, i) => (
        <FoodCard
          key={`prodList_${i}`}
          size={size}
          data={item}
          disable={disable}
          cartItems={cartItems}
          onSelect={didSelectItem}
          onAddToCart={didAddToCart}
          didAddRemove={didAddRemove}
        />
      )}
      keyExtractor={(item, key) => item.productoid.toString()}
    />
  );
};

const styles = {
  foodInfo: {
    height: 45,
    backgroundColor: "rgba(0,0,0,0.6)",
    padding: 10,
  },
  foodDetails: {
    fontSize: 21,
    color: "white",
    fontWeight: "400",
    textAlign:"center"
  }
}

export default ProductListView;
