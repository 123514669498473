import React from "react";
import {
  View,
  StyleSheet,
  TouchableOpacity,
  Dimensions,
  Image,
} from "react-native";

import { Text, Rating } from "react-native-elements";
import AppButton from "../Buttons/AppButton";

import { urlImage } from "../../utils/AppConst";

const deviceWidth = Math.round(Dimensions.get("window").width);

const Restaurant = ({ size, data, onSelect, disable = false }) => {
  const { nombre, sucursal, logo, direccion, foodType } = data.item;

  function mostrarFoto() {
    //let input = document.frmConvert.hex.value.replace(/[^A-Fa-f0-9]/g, "");
    if (logo % 2) {
        console.log("cleaned hex string length is odd.");
        return;
    }

    let binary = new Array();
    for (let i = 0; i < logo.length / 2; i++) {
        let h = logo.substr(i * 2, 2);
        binary[i] = parseInt(h, 16);
    }

    let byteArray = new Uint8Array(binary);
    let img = document.querySelector('.heximage');

    return window.URL.createObjectURL(new Blob([byteArray], { type: 'application/octet-stream' }));
    //document.body.appendChild(img)
}


  const mediumCard = () => {
    return (
      <View style={styles.root}>
        <TouchableOpacity onPress={() => onSelect(data)}>
          <Image style={styles.foodImage} source={{ uri: mostrarFoto() }} />
          <Text style={smallStyles.title}>{nombre}</Text>
        </TouchableOpacity>
      </View>
    );
  };

  const smallCard = () => {
    return (
      <TouchableOpacity
        style={smallStyles.smallCard}
        onPress={() => onSelect(data)}
        disabled={disable}
      >
        <Image style={smallStyles.foodImageSmall} source={{ uri: mostrarFoto() }} />
        <View style={smallStyles.productInfo}>
          <Text style={smallStyles.title}>{String(nombre).toUpperCase()} - {String(sucursal).toUpperCase()}</Text>
        </View>
      </TouchableOpacity>
    );
  };

  switch (size) {
    case "small": // wide card
      return smallCard();
    case "medium": // medium card
      return smallCard();
    default:
      return smallCard();
  }
};

const styles = StyleSheet.create({
  root: {
    flex: 1,
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "center",
    margin: 10,
  },
  foodImage: {
    borderRadius: 20,
    height: 220,
    width: deviceWidth - 30,
    justifyContent: "center",
    alignItems: "center",
    alignContent: "center",
  },
  title: {
    fontSize: 13,
    fontWeight: "460",
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
    marginTop: 10,
    color: "#636363",
  },
});

const smallStyles = StyleSheet.create({
  smallCard: {
    flex: 1,
    height: 100,
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    backgroundColor: "white",
    borderColor: "#E5E5E5",
    borderWidth: 1,
    borderRadius: 10,
    margin: 10,
  },

  title: {
    fontSize: 22,
    fontWeight: "600",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  resturentTitle: {
    fontSize: 18,
    fontWeight: "400",
    display: "flex",
    color: "#565555",
  },
  price: {
    fontSize: 18,
    fontWeight: "400",
    display: "flex",
    color: "#EA5656",
  },
  foodImageSmall: {
    borderRadius: 10,
    height: 99,
    width: 99,
    justifyContent: "flex-start",
    alignItems: "center",
    backgroundColor: "rgba(81, 77, 77, 0.25)",
    alignSelf: "center",
  },
  rating: {
    alignSelf: "flex-start",
  },
  productInfo: {
    flex: 1,
    padding: 5,
    justifyContent: "space-around",
  },
  shopView: {
    justifyContent: "space-between",
    padding: 10,
    alignItems: "center",
  },
  productSize: {
    fontSize: 20,
    fontWeight: "600",
    color: "#848484",
  },
});

export default Restaurant;
