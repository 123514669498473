import React, { useContext, useEffect, useState } from "react";
import {
  View,
  StyleSheet,
  TouchableOpacity,
  Image,
  Dimensions,
  Modal,
  ImageBackground,
} from "react-native";
import { SafeAreaView } from "react-navigation";
import AppButton from "../../components/Buttons/AppButton";
import { Text, Button } from "react-native-elements";
import BackIcon from "../../images/back_arrow.png";
import HomeIcon from "../../images/home_n_icon.png";
import SearchIcon from "../../images/search.png";
import GruposListView from "../../components/Listview/GruposListView";
import { urlImage } from "../../utils/AppConst";
import { navigate } from "../../utils/NavigationRef";
import { BackHandler, Alert } from 'react-native';

import { Context as UserContext } from "../../dataStore/userAccessContext";

const screenWidth = Dimensions.get("window").width;

const RestaurantDetails = ({ navigation }) => {
  const { onAddToCart, onViewCart } = useContext(UserContext);
  const [cerradoModal, setCerradoModal] = useState(false);

  const { params } = navigation.state;

  const {
    nombre,
    logo,
    description,
    direccion,
    foodType,
    grupos,
    sucursal,
    idsucursal,
  } = params;

  useEffect(() => {
    onViewCart();
    document.title = nombre;
    const backHandler = BackHandler.addEventListener(
      "hardwareBackPress",
      backAction
    );
    if(isClosed()){
      setCerradoModal(true);
    }
    return () => backHandler.remove();
  }, []);

  function isClosed() {
    const horarios = JSON.parse(
      JSON.parse(sessionStorage.getItem("configuracion")).horarios
    );

    const currentDay = new Date().getDay(); // Día actual de la semana (0 = domingo, 6 = sábado)
    const currentTime = new Date().toTimeString().substring(0, 5); // Hora actual en formato HH:mm

    const todaySchedule = horarios[getDayName(currentDay)]; // Horarios para el día actual
    if (!todaySchedule) return true; // Si no hay horario para el día actual, se considera cerrado

    const { apertura, cierre } = todaySchedule;

    return currentTime < apertura || currentTime > cierre; // Si está fuera del horario, la tienda está cerrada
  }

  function nextOpeningTime() {
      const horarios = JSON.parse(
        JSON.parse(sessionStorage.getItem("configuracion")).horarios
      );

      const currentDay = new Date().getDay();
      const currentTime = new Date().toTimeString().substring(0, 5);

      // Revisar si la tienda abrirá más tarde en el día actual
      const todaySchedule = horarios[getDayName(currentDay)];
      if (todaySchedule && currentTime < todaySchedule.apertura) {
        return `Hoy a las ${todaySchedule.apertura}`;
      }

      // Buscar el próximo día con horario de apertura
      for (let i = 1; i <= 7; i++) {
        const nextDay = (currentDay + i) % 7;
        const nextSchedule = horarios[getDayName(nextDay)];
        if (nextSchedule && nextSchedule.apertura) {
          return `el próximo ${getDayName(nextDay)} a las ${nextSchedule.apertura}`;
        }
      }

      return "próximamente"; // Si no hay horarios configurados
    }

    function getDayName(dayIndex) {
      const days = ["domingo", "lunes", "martes", "miercoles", "jueves", "viernes", "sabado"];
      return days[dayIndex];
    }


  const backAction = () => {
    Alert.alert("¿Quieres salir?", "¿Seguro que deseas salir de la aplicación?", [
      {
        text: "Cancelar",
        onPress: () => null,
        style: "cancel"
      },
      { text: "Salir", onPress: () => BackHandler.exitApp() }
    ]);
    return true; // Prevenir la acción por defecto
  };

  const didTapBack = () => {
    navigation.goBack();
  };

  const didSelectItem = (item) => {
    navigate("GruposDetails", item);
  };
  const buscarItem = () => {
    navigate("Search");
  };

  if (grupos.length > 0) {
    //navigate("GruposDetails", grupos[0]);
  }

  function mostrarFoto() {
    //let input = document.frmConvert.hex.value.replace(/[^A-Fa-f0-9]/g, "");
    if (logo % 2) {
        console.log("cleaned hex string length is odd.");
        return;
    }

    let binary = new Array();
    for (let i = 0; i < logo.length / 2; i++) {
        let h = logo.substr(i * 2, 2);
        binary[i] = parseInt(h, 16);
    }

    let byteArray = new Uint8Array(binary);
    let img = document.querySelector('.heximage');

    return window.URL.createObjectURL(new Blob([byteArray], { type: 'application/octet-stream' }));
    //document.body.appendChild(img)
}

if (cerradoModal) {
  return(
    <SafeAreaView style={stylesCerrado.contentView} forceInset={{ top: "always" }}>
    <Modal
    transparent={true}
    animationType="slide"
    visible={cerradoModal}
    onRequestClose={() => setCerradoModal(false)}
  >
    <View style={stylesCerrado.modalBackground}>
      <View style={stylesCerrado.modalContainer}>
        {/*<Image
          source={BackIcon} // Reemplaza con tu imagen
          style={stylesCerrado.closedImage}
        />*/}
        <Text style={stylesCerrado.modalTitle}>Establecimiento Cerrado</Text>
        <Text style={stylesCerrado.modalMessage}>
          Actualmente nos encontramos cerrados. El próximo horario de apertura es:
        </Text>
        <Text style={stylesCerrado.openingTime}>{nextOpeningTime()}</Text>

        <AppButton
          height={50}
          title="Aceptar"
          onTap={() => setCerradoModal(false)}
          style={stylesCerrado.acceptButton}
        />
      </View>
    </View>
  </Modal>
  </SafeAreaView>
  )
}

  return (
    <SafeAreaView style={styles.contentView} forceInset={{ top: "always" }}>
      <View style={styles.titleView}>
        {<TouchableOpacity onPress={() => console.log("Home")}>
          <Image style={styles.imgIcon} source={HomeIcon} />
        </TouchableOpacity>
      }
        <Text h4 style={{ flex: 1, textAlign: "center", marginRight: 0 }}>
          {nombre}
        </Text>
        <TouchableOpacity onPress={() => buscarItem()}>
          <Image style={styles.searchIcon} source={SearchIcon} />
        </TouchableOpacity>
      </View>
      <View style={styles.listView}>
        <ImageBackground
          source={{
            uri: mostrarFoto(),
          }}
          style={styles.coverImage}
        >
          {/*<View style={styles.foodInfo}>
            <Text
              style={{
                fontSize: 18,
                color: "#FFF",
                fontWeight: "500",
                marginTop: 5,
                textAlign:"center"
              }}
            >
              {direccion}
            </Text>
          </View>*/}
        </ImageBackground>
        <GruposListView
          size="small"
          disable={false}
          grupos={grupos}
          didSelectItem={didSelectItem}
        />
      </View>
    </SafeAreaView>
  );
};

const styles = StyleSheet.create({
  contentView: {
    backgroundColor: "#F2F2F2",
    flex: 1,
    justifyContent: "space-between",
  },
  titleView: {
    flex: 1,
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  listView: {
    flex: 11,
  },
  coverImage: {
    width: screenWidth,
    height: 220,
    justifyContent: "flex-end",
  },

  imgIcon: {
    width: 40,
    height: 50,
  },
  searchIcon: {
    width: 30,
    height: 30,
  },
  searchOptions: {
    display: "flex",
    height: 60,
    justifyContent: "space-around",
    flexDirection: "row",
    alignItems: "center",
    marginLeft: 10,
  },
  topCategory: {
    height: 100,
    backgroundColor: "green",
  },

  amountDetails: {
    flexDirection: "row",
    justifyContent: "space-between",
    padding: 10,
    margin: 5,
  },
  foodTitle: {
    color: "white",
    fontWeight: "700",
  },
  foodInfo: {
    height: 60,
    backgroundColor: "rgba(0,0,0,0.6)",
    padding: 10,
  },
  foodDetails: {
    fontSize: 18,
    color: "white",
    fontWeight: "400",
  },
});
const stylesCerrado = {
  contentView: {
    flex: 'auto',
    justifyContent: "center",
    height: '99vh',
  },
  modalBackground: {
    flex: 'auto',
    justifyContent: "center",
    height: '99vh',
    alignItems: "center",
    backgroundColor: "rgba(0, 0, 0, 0.5)", // Fondo semitransparente
  },
  modalContainer: {
    width: "80%",
    backgroundColor: "#fff",
    borderRadius: 20,
    padding: 20,
    alignItems: "center",
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5,
  },
  closedImage: {
    width: 100,
    height: 100,
    marginBottom: 20,
  },
  modalTitle: {
    fontSize: 24,
    fontWeight: "bold",
    color: "#FF6347", // Color atractivo
    marginBottom: 10,
  },
  modalMessage: {
    fontSize: 16,
    textAlign: "center",
    marginBottom: 10,
    color: "#333",
  },
  openingTime: {
    fontSize: 18,
    fontWeight: "600",
    color: "#3CB371", // Verde para un buen contraste
    marginBottom: 20,
  },
  acceptButton: {
    marginTop: 20,
  },
};

RestaurantDetails.navigationOptions = () => {
  return {
    header: null,
  };
};

export default RestaurantDetails;
