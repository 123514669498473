import React, { useContext, useEffect } from "react";
import { View, Text, StyleSheet, FlatList } from "react-native";
import GrupoCard from "../Cards/GrupoCard";
import { Context as UserContext } from "../../dataStore/userAccessContext";

const GruposListView = ({
  grupos,
  size,
  horizontal,
  didSelectItem,
  didAddToCart,
  didAddRemove,
  disable
}) => {
  const { state, onViewCart} = useContext(UserContext);
  const { cartItems } = state;
  useEffect(() => {
    onViewCart();
  }, []);

  return (
    <FlatList
      horizontal={horizontal ? true : false}
      showsHorizontalScrollIndicator={false}
      showsVerticalScrollIndicator={false}
      data={grupos}
      extraData={cartItems}
      renderItem={(item, i) => (
        <GrupoCard
          key={`GrupoList_${i}`}
          size={size}
          data={item}
          disable={disable}
          cartItems={cartItems}
          onSelect={didSelectItem}
          onAddToCart={didAddToCart}
          didAddRemove={didAddRemove}
        />
      )}
    />
  );
};

export default GruposListView;
